import { Breadcrumbs, Footer, H1, Header, Main, Page } from '@/design-system'
import Link from 'next/link'
import { sdk } from '../lib/sdk'

export async function getStaticProps() {
  const menu = (await sdk().menuItems()).menu_items

  return {
    props: { menu },
  }
}

export default function NotFoundPage({ menu }) {
  return (
    <Page>
      <Header menu={menu}>
        <Breadcrumbs>
          <Link href="/">Acasă</Link>
        </Breadcrumbs>
        <H1>Pagină inexistentă</H1>
      </Header>

      <Main>Nu există o pagină la această adresă.</Main>

      <Footer />
    </Page>
  )
}
